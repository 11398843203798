import React, { useState, useMemo } from 'react';
import { Search, Filter } from 'lucide-react';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';

// Table Component
const UsersTable = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('Shopper');
  const [statusFilter, setStatusFilter] = useState('all');
  const itemsPerPage = 7;

  // Filter data based on search and filters
  const filteredData = useMemo(() => {
    return data.filter(
      (item) =>
        (activeTab === 'all' || item.type === activeTab) &&
        (statusFilter === 'all' || item.status === statusFilter) &&
        Object.values(item).some((value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        
    );
  }, [data, searchTerm, activeTab, statusFilter]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full bg-gray-50 min-h-screen">
      {/* Top Navigation */}
      <div className="w-full bg-white border-b py-3 lg:flex">
        <div className="flex items-center gap-1 px-4 lg:w-1/2">
          <div className="border rounded-3xl ">
            <button
              className={`px-6 py-2 ${
                activeTab === 'Shopper'
                  ? 'text-gray-100 bg-[#84315E] rounded-3xl border-purple-600 font-medium'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('Shopper')}
            >
              Shopper
            </button>
            <button
              className={`px-6 py-2 ${
                activeTab === 'Designer'
                  ? 'text-gray-100 bg-[#84315E] rounded-3xl border-purple-600 font-medium'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('Designer')}
            >
              Designer
            </button>
          </div>
        </div>

        <div className="p-4 bg-white w-1/2">
        <div className="flex gap-2 items-center lg:justify-end">
          <div className="relative max-w-xs">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search in Product"
              className="pl-10 pr-4 py-2 w-full border rounded-md text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="px-4 py-2 border rounded-md flex items-center gap-2 text-sm bg-white">
            <Filter className="h-4 w-4" />
            Filter
          </button>
        </div>
      </div>
      </div>

      {/* Search and Filter */}


      {/* Table */}
      <div className="bg-gray-50">
        <div className=" w-full p-5 overflow-scroll">
          <table className="w-full ">
            <thead className='py-10'>
              <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                <th className="px-6 py-3 text-left text-xs">User ID</th>
                <th className="px-6 py-3 text-left text-xs">Name</th>
                <th className="px-6 py-3 text-left text-xs">Email</th>
                <th className="px-6 py-3 text-left text-xs">Role</th>
                <th className="px-6 py-3 text-left text-xs">Type</th>
                <th className="px-6 py-3 text-left text-xs">Account Status</th>
                <th className="px-6 py-3 text-left text-xs">Last Login</th>
                <th className="px-6 py-3 text-left text-xs">Purchase History</th>
                <th className="px-6 py-3 text-left text-xs">Action</th>
              </tr>
            </thead>
            <tbody className="bg-gray-50">
              {currentData.map((item, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm">{item.id}</td>
                  <td className="px-6 py-4 text-sm">{item.name}</td>
                  <td className="px-6 py-4 text-sm">{item.email}</td>
                  <td className="px-6 py-4 text-sm">{item.role}</td>
                  <td className="px-6 py-4 text-sm">{item.type}</td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium ${
                        item.status === 'Active'
                          ? 'bg-green-50 text-green-700'
                          : 'bg-red-50 text-red-700'
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm">{item.lastLogin}</td>
                  <td className="px-6 py-4 text-sm">{item.purchaseHistory}</td>
                  <td className="px-6 py-4">
                    <button className="text-gray-400 hover:text-gray-600">
                      <PiDotsThreeCircleVerticalLight className="h-5 w-5" />

                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
        <button
          className={`text-sm cursor-pointer ${
            currentPage === 1 ? 'text-black' : 'text-gray-500'
          }`}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        <div className="flex gap-1">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              className={`px-3 py-1 text-sm rounded-md ${
                currentPage === i + 1
                  ? 'bg-[#84315E] text-white'
                  : 'text-gray-500'
              }`}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <button
          className={`text-sm cursor-pointer ${
            currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'
          }`}
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UsersTable;

import React, { useState, useRef, useEffect } from 'react';
import { Info, Search } from 'lucide-react';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';
import { CiFilter } from 'react-icons/ci';
import DeleteConfirmationModal from '../../components/ui/DeleteModal';
import { useDispatch } from 'react-redux';
import { updateStatusCommunity } from '../../_actions/communityManage.action';
// import PostViewModal from './PostView';

const CommunityModerationTable = ({
    data,
    totalPages,
    currentPage,
    onPageChange,
    onSearchChange,
    searchTerm
}) => {
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [viewPost, setViewPost] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const dropdownRef = useRef(null);

    const [isStatusModal, setIsStatusModal] = useState(false)

    const toggleDropdown = (index) => {
        setDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAction = (action, data) => {
        switch (action) {
            case "STATUS":
                let payLoad = {
                    "communityId": data?._id,
                    "action": data?.isDisable ? 'ENABLE' : "DISABLE"
                }
                dispatch(updateStatusCommunity(payLoad))
                break;
            default:
                console.log("No Action")
        }
    }

    return (
        <div className="w-full bg-gray-50 min-h-screen">
            {/* Top Navigation */}
            <div className="w-full bg-white border-b py-3 lg:flex">
                <div className="flex items-center gap-1 px-4 lg:w-1/2 w-full">
                    <h1 className="text-[24px]">Community Moderation</h1>
                </div>

                <div className="p-4 bg-white lg:w-1/2 w-full">
                    <div className="flex gap-2 items-center lg:justify-end">
                        <div className="relative max-w-xs">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search in Product"
                                className="pl-10 pr-4 py-2 w-full border rounded-2xl text-sm"
                                value={searchTerm}
                                onChange={(e) => onSearchChange(e.target.value)}
                            />
                        </div>
                        <button className="px-4 py-2 border rounded-xl flex items-center gap-2 text-sm">
                            <CiFilter />
                            Filter
                        </button>
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className="bg-gray-50">
                <div className="overflow-x-scroll w-full p-5">
                    <table className="w-full">
                        <thead className="py-10">
                            <tr className="border-b bg-[#F1F5F9] font-extrabold text-[16px] text-black py-10">
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Discussion Agenda</th>
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Total Comment</th>
                                {/* <th className="px-6 py-3 text-left text-xs">Reports Date</th> */}
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Reports Status</th>
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Created Date</th>
                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Status</th>

                                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-gray-50">
                            {data.map((item, index) => (
                                <tr key={index} className="border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 text-sm">{item.id}</td>
                                    <td className="px-6 py-4 text-sm">
                                        <div className="flex items-center gap-6">
                                            <img src={item.image} alt={item.title} className="w-10 h-10 object-cover rounded" />
                                            <span>{item.title}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-sm">{item.totalComments}</td>
                                    {/* <td className="px-6 py-4 text-sm">{item.reportDate}</td> */}
                                    <td className="px-6 py-4 text-sm">
                                        <span className={`px-2 py-1 rounded-full text-xs ${item.status === 'Not yet' ? 'bg-purple-100 text-purple-600' : 'bg-red-100 text-red-600'
                                            }`}>
                                            {item.status}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-sm">{item.createdDate}</td>
                                    <td className="px-6 py-4 text-sm">{item.isDisable ? 'Inactive' : 'Active'}</td>

                                    <td className="px-6 py-4">
                                        <button className="text-gray-400 hover:text-gray-600" onClick={() => toggleDropdown(index)}>
                                            <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                                        </button>
                                        {dropdownOpen === index && (
                                            <div ref={dropdownRef} className="absolute right-10 mt-2 w-32 bg-white border rounded shadow">
                                                <ul className="flex flex-col">
                                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                        <button onClick={() => setIsDelete(true)}>
                                                            Delete
                                                        </button>
                                                    </li>
                                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                        <button onClick={() => handleAction("STATUS", item)}>
                                                            {item.isDisable ? 'Active' : 'Inactive'}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
                <button
                    className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-black' : 'text-gray-500'}`}
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    Prev
                </button>
                <div className="flex gap-1">
                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`px-3 py-1 text-sm rounded-md ${currentPage === i + 1 ? 'bg-[#84315E] text-white' : 'text-gray-500'
                                }`}
                            onClick={() => onPageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <button
                    className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'}`}
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    Next
                </button>
            </div>

            <DeleteConfirmationModal
                isOpen={isDelete}
                onClose={() => setIsDelete(false)}
                itemName="discussion"
            />
        </div>
    );
};

export default CommunityModerationTable;
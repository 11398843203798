// import React from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import SidebarJSON from './sidebar.json';
// import './Sidebar.css'
// import { FaCaretRight } from "react-icons/fa";
// import { CiLogout } from "react-icons/ci";
// import {  SlUser } from "react-icons/sl";
// import { FiHome } from "react-icons/fi";
// import { PiUsersLight } from "react-icons/pi";
// import { PiUsersThree } from "react-icons/pi";
// import { BsBadgeAd } from "react-icons/bs";
// import { PiCreditCard } from "react-icons/pi";
// import { GoQuestion } from "react-icons/go";
// import { TbDatabaseDollar } from "react-icons/tb";
// import { TbUsersGroup } from "react-icons/tb";
// import { PiFloppyDiskThin } from "react-icons/pi";
// import { TbAlignBoxCenterMiddle } from "react-icons/tb";
// import { CiCircleCheck } from "react-icons/ci";



// function Sidebar() {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [navbarOpen, setNavbarOpen] = React.useState(false);
//     const onClickMenu = (url) => {
//         navigate(url);
//         setTimeout(() => {
//             setNavbarOpen(!navbarOpen);
//         }, 150);
//     };
//     const ICON_MAP = {
//         FiHome,
//         PiUsersLight,
//         TbDatabaseDollar,
//         GoQuestion,
//         PiUsersThree,
//         BsBadgeAd,
//         PiCreditCard,
//         TbUsersGroup,
//         PiFloppyDiskThin,
//         TbAlignBoxCenterMiddle,
//         CiCircleCheck
//     };

//     return (
//         <>
//             <button className={`${navbarOpen ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-customColor z-50 h-16 lg:hidden`} onClick={() => setNavbarOpen(!navbarOpen)}>
//                 <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"></path>
//                 </svg>
//             </button>
//             <div className={`${navbarOpen ? 'flex' : 'hidden'} bg-[#FFFFFF] fixed left-0 inset-y-0 lg:static lg:flex max-w-64 w-64 min-w-[352px] z-50 flex-col`}>
//                 <div className="flex flex-col w-full relative flex-1 h-full max-h-full">
//                     <div onClick={() => onClickMenu('/app/dashboard')} className="cursor-pointer h-16 flex-shrink-0 px-4 p-2 bg-[#FFFFFF]  w-full z-50 justify-start text-clip">
//                         <img src="/Images/logo.png" className="w-24" />
//                         <p className="text-[#000000] workSans600 text-xs ps-5 uppercase">Dress well</p>
//                     </div>

//                     <nav className="overflow-y-auto px-4  flex flex-col flex-wrap  justify-between relative w-full z-10  h-full overflow-auto shadow shadow-[#BAB6B640] border-t-2 border-t-[#DDDDDD] pt-4 mt-5">
//                         <div className=" flex flex-col flex-wrap  w-full mx-auto flex-1">
//                             <div className="relative z-40 flex-1 w-full p-2 ">
                               
//                                  {SidebarJSON.WebView.map((ele, index) => {
//                                     const IconComponent =
//                                         ICON_MAP[ele.icon] || null;
                   
//                                     return (
//                                         <ul className="md:flex-col md:min-w-full flex flex-col list-none" key={index}>
//                                             <li className={` text-[12px] font-semibold p-4 w-full rounded-md  ${location.pathname.trim() === ele.location.trim() ? "bg-[#84315E] text-white" : "bg-[#FFFFFF]"}`}>
//                                                 <Link className={`text-sm capitalize flex justify-start place-items-center gap-2 items-center  ${location.pathname === ele.location.trim() ? "text-white" : "text-[#1E1E1E] hover:text-black"}`} to={ele.location}>
//                                                     {IconComponent && <IconComponent className="text-lg" />}
//                                                     {ele.label}
//                                                 </Link>
//                                             </li>
//                                         </ul>
//                                     )
//                                 })}
//                             </div>
//                             <div className=" bottom-16 mx-auto p-2 w-full">
//                                 <p className="w-[95%] mb-4 ms-1 border p-2 rounded-md flex justify-start place-items-center gap-3 bg-white hover:backdrop-blur-sm hover:text-white hover:bg-black transition-colors duration-200 cursor-pointer drop-shadow-sm">
//                                     <CiLogout />
//                                     Logout
//                                 </p>
//                             </div>


//                         </div>

//                     </nav>

//                 </div>
//             </div>
//         </>
//     );
// }

// export default Sidebar;



import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarJSON from './sidebar.json';
import './Sidebar.css';
import { FaCaretRight } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { SlUser } from "react-icons/sl";
import { FiHome } from "react-icons/fi";
import { PiUsersLight, PiUsersThree, PiCreditCard, PiFloppyDiskThin } from "react-icons/pi";
import { BsBadgeAd } from "react-icons/bs";
import { GoQuestion } from "react-icons/go";
import { TbDatabaseDollar, TbUsersGroup, TbAlignBoxCenterMiddle } from "react-icons/tb";
import { CiCircleCheck } from "react-icons/ci";

function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [navbarOpen, setNavbarOpen] = React.useState(false);

    // Function to handle navigation and close sidebar
    const onClickMenu = (url) => {
        navigate(url);
        setNavbarOpen(false); // Close sidebar immediately
    };

    // Icons mapping
    const ICON_MAP = {
        FiHome,
        PiUsersLight,
        TbDatabaseDollar,
        GoQuestion,
        PiUsersThree,
        BsBadgeAd,
        PiCreditCard,
        TbUsersGroup,
        PiFloppyDiskThin,
        TbAlignBoxCenterMiddle,
        CiCircleCheck
    };

    return (
        <>
            {/* Toggle Button (Only for Mobile) */}
            <button 
                className={`${navbarOpen ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-customColor z-50 h-16 lg:hidden`} 
                onClick={() => setNavbarOpen(!navbarOpen)}
            >
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"></path>
                </svg>
            </button>

            {/* Sidebar */}
            <div className={`${navbarOpen ? 'flex' : 'hidden'} bg-[#FFFFFF] fixed left-0 inset-y-0 lg:static lg:flex max-w-64 w-64 min-w-[352px] z-50 flex-col`}>
                <div className="flex flex-col w-full relative flex-1 h-full max-h-full">

                    {/* Logo Section */}
                    <div onClick={() => onClickMenu('/app/dashboard')} className="cursor-pointer h-16 flex-shrink-0 px-4 p-2 bg-[#FFFFFF] w-full z-50 justify-start text-clip">
                        <img src="/Images/logo.png" className="w-24" alt="Logo" />
                        <p className="text-[#000000] workSans600 text-xs ps-5 uppercase">Dress well</p>
                    </div>

                    {/* Sidebar Navigation */}
                    <nav className="overflow-y-auto px-4 flex flex-col flex-wrap justify-between relative w-full z-10 h-full overflow-auto shadow shadow-[#BAB6B640] border-t-2 border-t-[#DDDDDD] pt-4 mt-5">
                        <div className="flex flex-col w-full mx-auto flex-1">
                            <div className="relative z-40 flex-1 w-full p-2">
                                {SidebarJSON.WebView.map((ele, index) => {
                                    const IconComponent = ICON_MAP[ele.icon] || null;
                                    return (
                                        <ul className="md:flex-col md:min-w-full flex flex-col list-none" key={index}>
                                            <li 
                                                className={`text-[12px] font-semibold p-4 w-full rounded-md ${location.pathname.trim() === ele.location.trim() ? "bg-[#84315E] text-white" : "bg-[#FFFFFF]"}`}
                                            >
                                                <Link 
                                                    className={`text-sm capitalize flex justify-start place-items-center gap-2 items-center 
                                                        ${location.pathname === ele.location.trim() ? "text-white" : "text-[#1E1E1E] hover:text-black"}`} 
                                                    to={ele.location}
                                                    onClick={() => onClickMenu(ele.location)} // Close sidebar on click
                                                >
                                                    {IconComponent && <IconComponent className="text-lg" />}
                                                    {ele.label}
                                                </Link>
                                            </li>
                                        </ul>
                                    );
                                })}
                            </div>

                            {/* Logout Button */}
                            <div className="bottom-16 mx-auto p-2 w-full">
                                <p 
                                    className="w-[95%] mb-4 ms-1 border p-2 rounded-md flex justify-start place-items-center gap-3 bg-white hover:backdrop-blur-sm hover:text-white hover:bg-black transition-colors duration-200 cursor-pointer drop-shadow-sm"
                                    onClick={() => onClickMenu('/logout')} // Logout action
                                >
                                    <CiLogout />
                                    Logout
                                </p>
                            </div>

                        </div>
                    </nav>

                </div>
            </div>
        </>
    );
}

export default Sidebar;


import React from 'react'
import card from '../../../public/images/card.svg'

const Card = ({Aamount, title, Img_path}) => {
  return (
    <div>
        <div className='bg-white drop-shadow-lg shadow-[0px_8px_44px_rgba(132,49,94,0.15)] md:p-4 p-3 rounded-lg  '>
            <div className='flex justify-between items-start p-1'>
                <div className='space-y-2'>
                    <p className='text-[#8E95A9] font-medium text-base'>{title}</p>
                    <h2 className='text-[35px] font-[600]'>{Aamount}</h2>
                </div>

              <img src={Img_path} alt='card'/>
            </div>
        </div>
    </div>
  )
}

export default Card
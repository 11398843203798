import React, { useRef, useEffect } from 'react';
import { Search } from 'lucide-react';
import { FaPlus } from 'react-icons/fa';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';

const ProductTable = ({
  currentData,
  totalPages,
  currentPage,
  searchTerm,
  setSearchTerm,
  handlePageChange,
  isPopupOpen,
  setIsPopupOpen,
  dropdownOpen,
  toggleDropdown,
  isDelete,
  setIsDelete
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  return (
    <>
      {/* Top Navigation */}
      <div className="w-full bg-white border-b py-3 lg:flex">
        <div className="flex items-center gap-1 px-4 lg:w-1/2 w-full">
          <h1 className="text-[24px]">Product Management</h1>
        </div>

        <div className="p-4 bg-white lg:w-1/2 w-full">
          <div className="flex gap-2 items-center lg:justify-end">
            <div className="relative max-w-xs w-full">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search in Product"
                className="pl-10 pr-4 py-2 w-full border rounded-2xl text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              className="px-4 py-2 text-white border rounded-xl flex items-center gap-2 text-sm bg-[#84315E] whitespace-nowrap"
              onClick={() => setIsPopupOpen(true)}
            >
              <FaPlus className="h-4 w-4 text-white" />
              Add Product
            </button>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="bg-gray-50">
        <div className=" overflow-scroll w-full p-5">
          <table className="w-full">
            <thead className="py-10">
              <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Product ID</th>
                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Product Image/Name</th>
                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Designer Name</th>
                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Price</th>
                <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody className="bg-gray-50">
              {currentData.map((item, index) => (
                <tr key={item._id} className="border-b hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm">{item._id}</td>
                  <td className="px-6 py-4 text-sm">
                    <div className="flex items-center gap-6">
                      <img
                        src={item.productImage}
                        alt={item.title}
                        className="w-10 h-10 object-cover rounded"
                      />
                      <h1>{item.title}</h1>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm">{item.userId?.name || 'N/A'}</td>
                  <td className="px-6 py-4 text-sm">${item.price}</td>
                  <td className="px-6 py-4">
                    <button
                      className="text-gray-400 hover:text-gray-600"
                      onClick={() => toggleDropdown(index)}
                    >
                      <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                    </button>
                    {dropdownOpen === index && (
                      <div ref={dropdownRef} className="absolute right-10 mt-2 w-32 bg-white border rounded shadow">
                        <ul className="flex flex-col">
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            Edit
                          </li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            <button onClick={() => setIsDelete(true)}>
                              Delete
                            </button>
                          </li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            View
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
        <button
          className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-black' : 'text-gray-500'}`}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        <div className="flex gap-1">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              className={`px-3 py-1 text-sm rounded-md ${currentPage === i + 1
                  ? 'bg-[#84315E] text-white'
                  : 'text-gray-500'
                }`}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <button
          className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'
            }`}
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductTable;
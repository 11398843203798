// OrderTable.jsx
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Search, Filter, Info } from 'lucide-react';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
// import ViewFormPopup from './ViewModal';
import { DateRangePicker } from '../../components/callender/Callender';
import { FaAngleDown, FaPlus } from 'react-icons/fa';
import AddFormPopup from './NewAddModal';


// Sample data
const sampleOrders = [
    {
        id: "5543",
        orderNumber: "RE3431",
        name: "Jack Smith",
        product: "Product A",
        price: "$299",
        lastLogin: "10/12/2002",
        status: "Delivered",
        type: "Admin"
    },
    // Add more sample orders as needed
];

const sampleRefunds = [
    {
        id: "5543",
        orderNumber: "RE3431",
        name: "Jack Smith",
        requestType: "Admin",
        requestDate: "10/12/2002",
        status: "Delivered"
    },
    // Add more sample refunds as needed
];


const AdvertismentTable = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('Shopper');
    const [statusFilter, setStatusFilter] = useState('all');
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    
    const dropdownRef = useRef(null);
    const [dateRange, setDateRange] = useState({
        start: new Date(),
        end: new Date()
    });
    const itemsPerPage = 7;

    // Use appropriate data based on active tab
    const data = activeTab === 'Shopper' ? sampleOrders : sampleRefunds;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateRangeChange = (newRange) => {
        setDateRange(newRange);
    };

    // Filter data based on search and filters
    const filteredData = useMemo(() => {
        return data.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [data, searchTerm]);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const currentData = useMemo(() => {
        return filteredData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [filteredData, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleDropdown = (index) => {
        setDropdownOpen(prevIndex => prevIndex === index ? null : index);
    };
    const handleSave = (formData) => {
        console.log('Saved product data:', formData);
        // Handle the form submission here
    };

    const DesignerAdsTable = () => (
        <div className="bg-gray-50">
            <div className="overflow-x-scroll w-full p-5">
                <table className="w-full">
                    <thead className="py-10">
                        <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">#</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Promotion Name</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Target Audience</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Status</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Active Period</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Charges</th>
                            <th className="px-6 py-3 text-left text-xs whitespace-nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-50">
                        {currentData.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm">#{item.id}</td>
                                <td className="px-6 py-4 text-sm">{item.orderNumber}</td>
                                <td className="px-6 py-4 text-sm">{item.name}</td>
                                <td className="px-6 py-4 text-sm">{item.requestType}</td>
                                <td className="px-6 py-4 text-sm">{item.requestDate}</td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                                        item.status === 'Delivered' ? 'bg-green-50 text-green-700' :
                                        item.status === 'Pending' ? 'bg-yellow-50 text-yellow-700' :
                                        'bg-red-50 text-red-700'
                                    }`}>
                                        {item.status}
                                    </span>
                                </td>
                                <td className="px-6 py-4">
                                    <button className="text-gray-400 hover:text-gray-600">
                                        <Info className="h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    const ThirdPartyAdsTable = () => (
        <div className="bg-gray-50">
            <div className="overflow-x-hidden w-full p-5">
                <table className="w-full">
                    <thead className="py-10">
                        <tr className="border-b bg-white font-extrabold text-[16px] text-black py-10">
                            <th className="px-6 py-3 text-left text-xs">Order ID</th>
                            <th className="px-6 py-3 text-left text-xs">Customer Name</th>
                            <th className="px-6 py-3 text-left text-xs">Product</th>
                            <th className="px-6 py-3 text-left text-xs">Total Amount</th>
                            <th className="px-6 py-3 text-left text-xs">Order Date</th>
                            <th className="px-6 py-3 text-left text-xs">Status</th>
                            <th className="px-6 py-3 text-left text-xs">Action</th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-50">
                        {currentData.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm">{item.id}</td>
                                <td className="px-6 py-4 text-sm">{item.name}</td>
                                <td className="px-6 py-4 text-sm">{item.product}</td>
                                <td className="px-6 py-4 text-sm">{item.price}</td>
                                <td className="px-6 py-4 text-sm">{item.lastLogin}</td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                                        item.status === 'Delivered' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                                    }`}>
                                        {item.status}
                                    </span>
                                </td>
                                <td className="px-6 py-4">
                                    <button className="text-gray-400 hover:text-gray-600" onClick={() => toggleDropdown(index)}>
                                        <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                                    </button>
                                    {dropdownOpen === index && (
                                        <div ref={dropdownRef} className="absolute right-10 mt-2 w-32 bg-white border rounded shadow">
                                            <ul className="flex flex-col">
                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Update</li>
                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                    <button onClick={() => setIsPopupOpen(true)}>View</button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="w-full bg-gray-50 min-h-screen">
        {/* Top Navigation */}
        <div className="w-full bg-white border-b py-3 xl:flex">
            <div className="flex items-center gap-1 px-4  xl:w-1/2">
                <div className="border rounded-3xl">
                    <button
                        className={`px-4 py-2 text-sm ${
                            activeTab === 'Shopper' ? 'text-gray-100  bg-[#84315E] rounded-3xl border-purple-600 font-medium' : 'text-gray-500 '
                        }`}
                        onClick={() => setActiveTab('Shopper')}
                    >
                        Designer Ads Management
                    </button>
                    <button
                        className={`px-4 py-2 text-sm ${
                            activeTab === 'Designer' ? 'text-gray-100  bg-[#84315E] rounded-3xl border-purple-600 font-medium' : 'text-gray-500 '
                        }`}
                        onClick={() => setActiveTab('Designer')}
                    >
                        Third Party Ads Management
                    </button>
                </div>
            </div>

            <div className="p-4 bg-white  xl:w-1/2">
                <div className="flex gap-2 items-center xl:justify-end">
                    <div className="relative max-w-xs">
                        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search in Product"
                            className="pl-10 pr-4 py-2 w-full border rounded-3xl text-sm"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <button className="px-4 py-2 border rounded-3xl flex items-center gap-2 text-sm bg-white">
                        <Filter className="h-4 w-4" />
                        Filter
                    </button>
                   
                    {/* Only show Export button for Order Monitoring */}
                    {activeTab === 'Shopper' && (
                        <div className="bg-[#84315E] rounded-3xl">
                            <button className="text-white w-full flex items-center gap-2 py-2 px-4 text-[15px] whitespace-nowrap"
                            onClick={() => setIsAddPopupOpen(true)}
                            >
                            <FaPlus />
                                Create New Ads
                            </button>
                        </div>
                        
                    )}
                    {/* {activeTab === 'Designer' && (
                        <div className="bg-white border  rounded-3xl">
                            <button className="text-black p-2 flex items-center gap-2 px-4">
                                
                                Refund
                                <FaAngleDown />
                            </button>
                        </div>
                    )} */}
                </div>
            </div>
            <AddFormPopup
                    isOpen={isAddPopupOpen}
                    onClose={() => setIsAddPopupOpen(false)}
                    onSave={handleSave}
                />
        </div>

            {/* Render different tables based on active tab */}
            {activeTab === 'Shopper' ? <DesignerAdsTable /> : <ThirdPartyAdsTable />}

            {/* Pagination */}
            <div className="flex justify-end gap-10 items-center p-4 bg-white border-t">
                <button
                    className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-black' : 'text-gray-500'}`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    Prev
                </button>
                <div className="flex gap-1">
                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`px-3 py-1 text-sm rounded-md ${
                                currentPage === i + 1 ? 'bg-[#84315E] text-white' : 'text-gray-500'
                            }`}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <button
                    className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'}`}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next
                </button>
            </div>

            {/* View Modal */}
            {/* <ViewFormPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSave={(formData) => {
                    console.log('Saved:', formData);
                    setIsPopupOpen(false);
                }}
            /> */}
        </div>
    );
};

export default AdvertismentTable;
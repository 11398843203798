import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { logoutFunction } from "../../_helpers";
import { IoReorderThree } from "react-icons/io5";
import { FaBell } from "react-icons/fa"; 

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ HeaderJSON, headerName }) {
  const handelLogout = () => {
    logoutFunction();
  };

  console.log('headerName======>>>>>>>>', headerName)

  return (
    <Fragment>
      <div className="bg-[#FFFFFF] relative z-10 flex-shrink-0 flex h-16 shadow"
        style={{ boxShadow: "rgba(139, 137, 137, 0.25)" }}
      >
        <div className="flex-1 px-4 flex lg:justify-between justify-end items-center">
          {/* Left Section: Menu Icon */}
          <h2 className="items-center gap-6 font-semibold lg:flex hidden">
            {headerName}
          </h2>

          <div className="flex items-center space-x-6">

            <div className="relative cursor-pointer">
              <FaBell className="text-xl text-gray-500" />
              <span className="absolute top-0 right-0 inline-flex items-center justify-center h-4 w-4 rounded-full bg-red-600 text-white text-xs">
                6
              </span>
            </div>

            <div className="flex items-center">
              <div className="mr-4">
                <span className="font-semibold">{HeaderJSON["Admin-name"]}</span>
                <span className="block text-sm text-gray-500">Admin</span>
              </div>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
                      <img
                        src={`/${HeaderJSON["Admin-image"]}`}
                        alt="User avatar"
                        className="rounded-full w-10 h-10"
                      />
                    </Menu.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={handelLogout}
                                className={classNames(
                                  active ? "bg-shine text-gray-900" : "text-gray-900",
                                  "cursor-pointer block px-4 py-2 text-medium hover:bg-slate-600 hover:text-white"
                                )}
                              >
                                Logout
                              </span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import App from "../components/App";
import NetworkDetector from "../components/Hoc/NetworkDetector";

export function logoutFunction() {
    window.sessionStorage.removeItem('adminuser');
    window.location.replace('/login');
}

export function authHeader() {
    let user = JSON.parse(window.sessionStorage.getItem('adminuser'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        console.error('No valid token found.');
        return {};
    }
}

export const headerForPublicAPI = {
    'Content-Type': 'application/json',
};

export const headerForPrivateAPI = () => {
    const header = {
        'Content-Type': 'application/json'
    };

    const token = authHeader().Authorization;
    console.log('header---->', token)
    if (token) {
        header["Authorization"] = token;
    }
    return header;
};



export const APIcallFunction = async (credentials) => {
    const baseUrl = "https://dev-eyision.jamsara.com/api/v1";
    let url = baseUrl + credentials.endPoint;
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header || headerForPublicAPI
    };
    if(credentials.method === 'GET') {
        const queryParams = new URLSearchParams(credentials.body).toString();
        url += `?${queryParams}`;
    } else {
        requestOptions.body = credentials.body ? JSON.stringify(credentials.body) : null;
    }


    console.log('requestOptionsrequestOptions', requestOptions)

    try {
        const response = await fetch(url, requestOptions);
        const responseData = await handleResponse(response);
        return {
            data: responseData.data,
        };
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


// export default NetworkDetector(App);
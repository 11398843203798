import React from 'react';
import { X, Share2, Eye, MessageCircle, Heart } from 'lucide-react';

const PostViewModal = ({ isOpen, onClose, post }) => {
  if (!isOpen || !post) return null;

  // Get image URL from either transformed data or original data structure
  const imageUrl = post.image || (post.content && post.content[0]) || "/api/placeholder/400/400";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4" onClick={onClose}>
      <div className="bg-white rounded-lg w-full max-w-xl max-h-[90vh] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Post Details</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {/* Post Image */}
          <div className="mb-6 flex justify-center">
            <img
              src={imageUrl}
              alt={post.title}
              className=" h-96 object-cover rounded-lg"
              onError={(e) => {
                e.target.src = "/api/placeholder/400/400";
                e.target.onerror = null; // Prevent infinite loop
              }}
            />
          </div>

          {/* Post Info */}
          <div className="space-y-4">
            <div>
              <h3 className="text-2xl font-bold mb-2">{post.title}</h3>
              <p className="text-gray-600">{post.desc}</p>
            </div>

            {/* Stats */}
            <div className="flex items-center gap-6 py-4 border-t border-b">
              <div className="md:flex items-center  gap-2">
                <Heart className="h-5 w-5 mx-auto text-gray-500" />
                <span className='whitespace-nowrap' >{post.like || post.likes || 0} Likes</span>
              </div>
              <div className="md:flex  items-center gap-2">
                <MessageCircle className="h-5 w-5 mx-auto text-gray-500" />
                <span className='whitespace-nowrap'>{post.comments || post.totalComments || 0} Comments</span>
              </div>
              <div className="md:flex  items-center gap-2">
                <Share2 className="h-5 w-5 mx-auto text-gray-500" />
                <span className='whitespace-nowrap' >{post.share || post.shares || 0} Shares</span>
              </div>
              <div className="md:flex items-center gap-2">
                <Eye className="h-5 w-5 mx-auto text-gray-500" />
                <span className='whitespace-nowrap' >{post.views || 0} Views</span>
              </div>
            </div>

            {/* Additional Details */}
            <div className="space-y-2">
              <div className="flex justify-between  text-sm">
                <span className="text-gray-600">Status:</span>
                <span className={`px-2 py-1 rounded-full text-xs ${
                  post.status === 'Not yet' ? 'bg-purple-100 text-purple-600' : 'bg-red-100 text-red-600'
                }`}>
                  {post.status}
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Created Date:</span>
                <span>{post.createdDate}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Report Date:</span>
                <span>{post.reportDate}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Post ID:</span>
                <span className="font-mono">{post._id}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 p-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostViewModal;
import React from 'react'
import InputField from '../../../components/ui/InputFeilds/InputFeilds'
import Button from '../../../components/ui/Button/Button'



const EditModal = ({ onCancel, onSubmit, title, inputData, handleONChange, error }) => {
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-[50rem] m-4">
                    <div className='border-b w-full'>
                        <div className="flex justify-between items-center border-b pb-2 p-6">
                            <h3 className="text-lg font-semibold">{title}</h3>
                            <button
                                className="text-gray-500 text-2xl w-10 h-10 bg-gray-100 rounded-full  hover:text-gray-800"
                                onClick={onCancel}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                    <div className=' p-4'>
                        <div className='grid grid-cols-1 gap-4 items-center'>
                            <div>
                                <InputField label={`Designer Name`} name='name' value={inputData?.designer} onChange={handleONChange} />
                                {error?.name && <p className="text-red-500 text-sm">{error?.name}</p>}
                            </div>
                            <div>
                                <InputField label={`Current Rate`} name='currentRate'  value={inputData?.currentRate} onChange={handleONChange} />
                                {error?.currentRate && <p className="text-red-500 text-sm">{error?.currentRate}</p>}
                            </div>
                        </div>
                        <InputField label={`Custom Rate`} name='customRate' value={inputData?.customRate} onChange={handleONChange} />
                        {error?.customRate && <p className="text-red-500 text-sm">{error?.customRate}</p>}

                    </div>


                    <div className="flex justify-start space-x-3 mt-6 border-t p-4">
                        <Button label={`Save`} onClick={onSubmit} className={`bg-[#84315E] text-white`}/>
                        <Button label={`Cancel`} onClick={onCancel} className={` text-black border `}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditModal

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Info, Search } from 'lucide-react';
// import { FaPlus } from 'react-icons/fa';
// import ProductFormPopup from './AddProductModal';
// import DeleteConfirmationModal from './DeleteModal';
import { PiDotsThreeCircleVerticalLight } from 'react-icons/pi';
import DeleteConfirmationModal from '../../components/ui/DeleteModal';
import InputField from '../../components/ui/InputFeilds/InputFeilds';
import Input from '../../components/ui/Input/Input';
import EditModal from './Modal/EditModal';
import { useDispatch } from 'react-redux';
import { editCommition } from '../../_actions/commision.action';

const CommisionTable = ({ data }) => {
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false)
    const [clickedRowData, setIsClickedRowData] = useState(null)

    const [error, setErros] = useState()

    const itemsPerPage = 7;
    const dropdownRef = useRef(null);

    const handleEditValidate = () => {
        let newError = {}
        if (clickedRowData?.customRate > 100) {
            newError.customRate = "Custom Rate must be 100 or less";
        }
        if (clickedRowData?.currentRate > 100) {
            newError.currentRate = "Current Rate must be 100 or less";
        }
        setErros(newError);
        return Object.keys(newError).length === 0

    }

    // Filter data based on search
    const filteredData = useMemo(() => {
        return data.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [data, searchTerm]);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const currentData = useMemo(() => {
        return filteredData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [filteredData, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleDropdown = (index) => {
        setDropdownOpen(prevIndex => prevIndex === index ? null : index);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAction = (action, data) => {
        setIsClickedRowData(data)
        switch (action) {
            case "EDIT":
                setIsEditModal(prevState => !prevState)
                break;
            case "DELETE":
                break;
            default:
                console.log("no action found")
        }
    }

    const toggleEditModal = () => {
        setIsEditModal(prevState => !prevState)
    }

    const handleONChange = (e) => {
        const { name, value } = e.target;
        setIsClickedRowData({ ...clickedRowData, [name]: value })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleEditValidate()) {
            let apiPayload = {
                "commisionId": clickedRowData?.id,
                "userId": clickedRowData?.designerId,
                "currentRateInPer": clickedRowData?.currentRate,
                "customRateInPer": clickedRowData?.customRate
            }
            dispatch(editCommition(apiPayload))
            setIsEditModal(prevState => !prevState)
            setIsClickedRowData({})
        }


    }
    console.log('currentDatacurrentData', currentData)
    return (
        <div className="w-full  min-h-screen p-4">
            {/* Top Navigation */}
            <div className="w-full   py-3 lg:flex ">
                <div className="flex items-center gap-1 px-4 lg:w-1/2 w-full">
                    <h1 className='text-[24px]'>Commission & Revenue Management</h1>
                </div>

                <div className="p-4 lg:w-1/2 w-full">
                    <div className="flex gap-2 items-center lg:justify-end">
                        <div className="relative max-w-xs">
                            <Input value={searchTerm} placeholder={`Search..`}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className="">
                <div className="overflow-x-scroll w-full p-5">
                    <table className="min-w-full table-auto border-collapse border border-[#E2E8F0]">
                        <thead className='bg-[#F1F5F9]'>
                            <tr className="border-b bg-[#F1F5F9] font-extrabold text-[16px] text-black py-10">
                                <th className="py-4 px-4 text-left text-sm  text-black font-semibold whitespace-nowrap">Designer name</th>
                                <th className="py-4 px-4 text-left text-sm  text-black font-semibold whitespace-nowrap">Current Rate</th>
                                <th className="py-4 px-4 text-left text-sm  text-black font-semibold whitespace-nowrap">Custom Rate</th>
                                <th className="py-4 px-4 text-left text-sm  text-black font-semibold whitespace-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {currentData.map((item, index) => (
                                <tr key={item.id} className="border-t border-gray-200 text-sm capitalize text-[#475569]">
                                    <td className="px-6 py-4 text-sm">
                                        {item.designer}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {item.currentRate} %
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        {item.customRate} %
                                    </td>
                                    <td className="px-6 py-4 relative">
                                        <button
                                            className="text-gray-400 hover:text-gray-600"
                                            onClick={() => toggleDropdown(index)}
                                        >
                                            <PiDotsThreeCircleVerticalLight className="h-5 w-5" />
                                        </button>
                                        {dropdownOpen === index && (
                                            <div
                                                ref={dropdownRef}
                                                className="absolute top-1/2 z-50 w-32"
                                            >
                                                <ul className="flex flex-col bg-white border rounded ">
                                                    <li className="px-4 py-2 hover:bg-[#84315E]/10 cursor-pointer border-b"
                                                        onClick={() => handleAction('EDIT', item)}
                                                    >
                                                        Edit
                                                    </li>
                                                    <li className="px-4 py-2 hover:bg-[#84315E]/10 cursor-pointer">
                                                        <button onClick={() => handleAction('DELETE', item)}>
                                                            Remove
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <div className="flex justify-end gap-10 items-center p-4">
                <button
                    className={`text-sm cursor-pointer ${currentPage === 1 ? 'text-black' : 'text-gray-500'}`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    Prev
                </button>
                <div className="flex gap-1">
                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`px-3 py-1 text-sm rounded-md ${currentPage === i + 1
                                ? 'bg-[#84315E] text-white'
                                : 'text-gray-500'
                                }`}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <button
                    className={`text-sm cursor-pointer ${currentPage === totalPages ? 'text-gray-800' : 'text-gray-500'
                        }`}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next
                </button>
            </div>

            <DeleteConfirmationModal
                isOpen={isDelete}
                onClose={() => setIsDelete(false)}
                itemName="product list"
            />
            {
                isEditModal && (<EditModal title={`Edit`} onCancel={toggleEditModal} inputData={clickedRowData} handleONChange={handleONChange} onSubmit={handleSubmit} error={error} />)
            }
        </div>
    );
};
export default CommisionTable;
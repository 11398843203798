import React, { useState } from "react";
import { X } from "lucide-react";

const AddFormPopup = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    advertiserName: "",
    adType: "",
    adStartDate: "",
    adEndType: "",
    status: "Pending",
    adDescription: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-2xl relative z-50 m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <h2 className="text-2xl font-bold mb-6">Create New Ads</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block mb-2">Advertiser Name</label>
              <input
                type="text"
                name="advertiserName"
                value={formData.advertiserName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Request ID"
              />
            </div>
            <div>
              <label className="block mb-2">Ad Type</label>
              <input
                type="text"
                name="adType"
                value={formData.adType}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Order ID"
              />
            </div>
            <div>
              <label className="block mb-2">Ad Start Date</label>
              <input
                type="date"
                name="adStartDate"
                value={formData.adStartDate}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">Ad End Type</label>
              <input
                type="text"
                name="adEndType"
                value={formData.adEndType}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Contact Info"
              />
            </div>
            <div>
              <label className="block mb-2">Upload Ad File</label>
              <input
                type="file"
                name="adFile"
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">Status</label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Ad Description</label>
            <textarea
              name="adDescription"
              value={formData.adDescription}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              placeholder="Ad Description"
            />
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-[#84315E] text-white py-2 px-8 rounded-3xl hover:bg-purple-800"
            >
              Create Ad
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-200 text-gray-700 py-2 px-8 rounded-3xl hover:bg-gray-300"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFormPopup;

import React from 'react';

const TopDesignerTable = ({ items }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md overflow-scroll">
      <div className="flex justify-between items-center pb-4 border-b bg-white">
        <h2 className="text-xl font-bold">Top Designer</h2>
        
      </div>
      <table className="w-full mt-4 border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-3 text-left text-gray-600">Name</th>
            <th className="p-3 text-left text-gray-600">Rating</th>
            <th className="p-3 text-left text-gray-600">Earn</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="border-b bg-white hover:bg-gray-50">
              <td className="p-3 flex items-center gap-3">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-10 h-10 rounded-full object-cover"
                />
                <span className="font-semibold">{item.title}</span>
              </td>
              <td className="p-3">{item.rating}</td>
              <td className="p-3">{item.earning}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopDesignerTable;
